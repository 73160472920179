import { produce } from 'immer';
import { create } from 'zustand';
import { VaultClient } from '@drift-labs/vaults-sdk';
import { UiVaultConfig, VaultStats } from 'src/@types/vaults';
import { UiVaults } from 'src/constants/vaults';

export type VaultStatWithConfig = VaultStats & { uiVaultConfig: UiVaultConfig };

export interface VaultsStore {
	set: (x: (s: VaultsStore) => void) => void;
	get: () => VaultsStore;
	vaultClient: VaultClient | undefined;
	/**
	 * Vaults stats of vaults. Updates when the oracle price stores are updated (see `useSyncVaultsStats`)
	 */
	vaultsStats: Record<string, VaultStats>;
	areInitialVaultsStatsLoaded: boolean;

	/**
	 * Returns a list of vault stats with their respective ui vault config
	 */
	getVaultsStatsList: (filters?: {
		age?: 'new' | 'old' | 'all';
	}) => VaultStatWithConfig[];

	/**
	 * Returns the stats for a given vault
	 */
	getVaultStats: (vaultPubkey: string) => VaultStats | undefined;

	syncCurrentPageVaultStats: () => Promise<void>;

	// needed for mobile filters
	setVaultDisplaySettings: (
		settings: Partial<VaultsStore['vaultDisplaySettings']>
	) => void;

	getVaultDisplaySettings: () => VaultsStore['vaultDisplaySettings'];

	vaultDisplaySettings: {
		depositedOnly: boolean;
		targetVaultManager: string;
		targetDepositAsset: string;
		targetApyPeriod: string;
		sortedBy: string;
	};
}

export const useVaultsStore = create<VaultsStore>((set, get) => ({
	set: (fn) => set(produce(fn)),
	get: () => get(),
	vaultClient: undefined,
	vaultsStats: {},
	areInitialVaultsStatsLoaded: false,

	getVaultsStatsList: (filters?: { age?: 'new' | 'old' | 'all' }) => {
		const vaultStats = get().vaultsStats;
		return Object.keys(vaultStats)
			.map((vaultPubkey) => ({
				...vaultStats[vaultPubkey],
				uiVaultConfig: UiVaults.getVaultConfig(vaultPubkey),
			}))
			.filter((vaultStat) => !vaultStat.uiVaultConfig.hidden)
			.filter((vaultStat) => {
				if (filters?.age === 'new') {
					return vaultStat.uiVaultConfig.isNew(
						vaultStat.numOfVaultSnapshots ?? 0
					);
				}
				if (filters?.age === 'old') {
					return !vaultStat.uiVaultConfig.isNew(
						vaultStat.numOfVaultSnapshots ?? 0
					);
				}
				return true;
			});
	},

	getVaultStats: (vaultPubkey: string) => {
		const vaultStats = get().vaultsStats;
		return vaultStats[vaultPubkey];
	},

	syncCurrentPageVaultStats: () => Promise.resolve(),

	vaultDisplaySettings: {
		depositedOnly: false,
		targetVaultManager: 'All',
		targetDepositAsset: 'All',
		targetApyPeriod: '90d',
		sortedBy: 'tvl',
	},

	getVaultDisplaySettings: () => {
		return get().vaultDisplaySettings;
	},

	setVaultDisplaySettings: (settings) => {
		set((state) => ({
			vaultDisplaySettings: {
				...state.vaultDisplaySettings,
				...settings,
			},
		}));
	},
}));
