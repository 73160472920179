'use client';

import { useEffect } from 'react';
import useDriftClient from 'src/hooks/useDriftClient';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import { getDriftVaultProgram, VaultClient } from '@drift-labs/vaults-sdk';
import useDriftStore from '../DriftStore/useDriftStore';
import { COMMON_UI_UTILS } from '@drift/common';
import { useVaultsStore } from './useVaultsStore';

export const useSyncVaultClient = () => {
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const connection = useDriftStore((s) => s.connection.current);
	const setVaultsStore = useVaultsStore((s) => s.set);

	useEffect(() => {
		if (driftClientIsReady && driftClient && connection) {
			const vaultProgram = getDriftVaultProgram(
				connection,
				COMMON_UI_UTILS.createThrowawayIWallet()
			);
			const vaultClient = new VaultClient({
				driftClient,
				program: vaultProgram,
			});
			setVaultsStore((s) => {
				s.vaultClient = vaultClient;
			});
		}
	}, [driftClient, driftClientIsReady, connection]);
};
