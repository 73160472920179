import {
	BigNum,
	BN,
	FuelOverflowAccount,
	UserStatsAccount,
} from '@drift-labs/sdk';
import { IconProps } from '@drift-labs/icons';
import { JSX } from 'react';
import { MarketId } from '@drift/common';
import dayjs from 'dayjs';
import { Vault } from '@drift-labs/vaults-sdk';

export type PeriodApys = {
	'7d': number;
	'30d': number;
	'90d': number;
};

export type PeriodRois = {
	'7d': number;
	'30d': number;
	'90d': number;
};

export type ApyReturnsLookup = Record<
	string,
	{
		apys: PeriodApys;
		maxDrawdownPct: number;
		numOfVaultSnapshots: number;
	}
>;

export interface Smoothing {
	reset: () => void;
	sample: (newSample: number, currentTimeMs: number) => number;
	setLastSample: (lastSample: number, lastSampleTimeMs: number) => void;
}

export type VaultManagerConfig = {
	name: string;
	imgSrc: string;
	twitterHandle: string;
};

export type OverviewSection = {
	title: string;
	paragraphs: {
		link?: string;
		className?: string;
		/**
		 * The reason why this can be a string[], is to allow for placeholders to be
		 * replaced with dynamic values.
		 *
		 * E.g. text: ['Deposited funds are subject to a ', FEES_PLACEHOLDER, ' redemption period.'],
		 */
		text?: string[] | string;
		contacts?: {
			name: string;
			link: string;
			icon: (allProps: IconProps) => JSX.Element;
		}[];
	}[];
};

const NUM_OF_DAYS_TO_BE_NEW = 30;

/*
 * IMPORTANT: Only serializable attributes can be placed in this config interface, since this will be stored remotely as a JSON.
 */
export interface SerializableNativeUiVaultConfig {
	name: string;
	vaultManager: VaultManagerConfig;
	vaultPubkeyString: string;
	vaultManagerPubkeyString: string;
	driftUserPubkeyString: string;
	description: string;
	depositAsset: number;
	assetsOperatedOn: number[];
	smoothing?: Smoothing;
	featured?: boolean;
	overviewSection?: OverviewSection[];
	externalUiLink?: string;
	temporaryApy?: number;
	new?: boolean;
	hidden?: boolean;
	isNotionalGrowthStrategy?: boolean;
	isHighRisk?: boolean;
	isVerified?: boolean;
}

export class UiVaultConfig implements SerializableNativeUiVaultConfig {
	protected _config: SerializableNativeUiVaultConfig;

	constructor(config: SerializableNativeUiVaultConfig) {
		this._config = config;
	}

	get name() {
		return this._config.name;
	}
	get vaultManager() {
		return this._config.vaultManager;
	}
	get vaultPubkeyString() {
		return this._config.vaultPubkeyString;
	}
	get vaultManagerPubkeyString() {
		return this._config.vaultManagerPubkeyString;
	}
	get driftUserPubkeyString() {
		return this._config.driftUserPubkeyString;
	}
	get description() {
		return this._config.description;
	}
	get depositAsset() {
		return this._config.depositAsset;
	}
	get assetsOperatedOn() {
		return this._config.assetsOperatedOn;
	}
	get smoothing() {
		return this._config.smoothing;
	}
	get featured() {
		return this._config.featured;
	}
	get overviewSection() {
		return this._config.overviewSection;
	}
	get externalUiLink() {
		return this._config.externalUiLink;
	}
	get temporaryApy() {
		return this._config.temporaryApy;
	}
	get hidden() {
		return this._config.hidden;
	}
	get isNotionalGrowthStrategy() {
		return this._config.isNotionalGrowthStrategy;
	}
	get externalDataApiAdapter(): IExternalVaultApiAdapter {
		return undefined;
	}
	get isDriftVaultsProgramVault() {
		return true;
	}
	get config() {
		return this._config;
	}

	get isHighRisk() {
		return this._config.isHighRisk;
	}

	isNew(numOfVaultSnapshots: number) {
		return numOfVaultSnapshots < NUM_OF_DAYS_TO_BE_NEW;
	}

	get isVerified() {
		return this._config.isVerified;
	}
}

/**
 * External vaults need to be hardcoded, because its not serializable (e.g. externalDataApiAdapter).
 */
export type IExternalUiVaultConfig = SerializableNativeUiVaultConfig & {
	externalDataApiAdapter: IExternalVaultApiAdapter;
	externalUiLink: string;
	startTs: number;
};

export class ExternalUiVaultConfig
	extends UiVaultConfig
	implements IExternalUiVaultConfig
{
	private _externalDataApiAdapter: IExternalVaultApiAdapter;
	private _externalUiLink: string;
	private _startTs: number;

	constructor(config: IExternalUiVaultConfig) {
		if (!config.externalDataApiAdapter || !config.externalUiLink) {
			throw new Error('External Vault missing required props');
		}

		super(config);
		this._externalDataApiAdapter = config.externalDataApiAdapter;
		this._externalUiLink = config.externalUiLink;
		this._startTs = config.startTs;
	}

	get externalDataApiAdapter() {
		return this._externalDataApiAdapter;
	}

	get isDriftVaultsProgramVault() {
		return false;
	}

	get externalUiLink() {
		return this._externalUiLink;
	}

	get startTs() {
		return this._startTs;
	}

	isNew() {
		return dayjs
			.unix(this._startTs)
			.isAfter(dayjs().subtract(NUM_OF_DAYS_TO_BE_NEW, 'days'));
	}
}

/**
 * Stats that are derived from on-chain data.
 */
export interface OnChainVaultStats {
	vaultAccount: Vault;
	vaultUserStatsAccount: UserStatsAccount;
	vaultFuelOverflowAccount: FuelOverflowAccount | undefined;
	capacityPct: number;
	isUncappedCapacity: boolean;
	totalBasePnl: BigNum;
	totalQuotePnl: BigNum;
	tvlBase: BigNum;
	tvlQuote: BigNum;
	volume30Days: BigNum;
	isOnChainStatsLoaded: boolean;
	totalShares: BigNum;
	profitShare: number;
	vaultRedeemPeriodSecs: BN;
	notionalGrowthQuotePnl: BigNum;
	hasLoadedOnChainStats: boolean;
	fuelEarned: number;
}

/**
 * Stats that are derived from off-chain data.
 */
export interface OffChainVaultStats {
	apys: PeriodApys;
	maxDrawdownPct: number;
	numOfVaultSnapshots: number;
	hasLoadedOffChainStats: boolean;
}

export type VaultStats = OnChainVaultStats &
	OffChainVaultStats & {
		// These types are attached for external vaults where we don't have internal things like snapshots etc. to use. See ElementalApiAdapter for an example.
		externalExtraStats?: {
			rois: PeriodRois;
		};
	};

export interface IExternalVaultApiAdapter {
	getVaultStats: (
		getOraclePrice: (marketId: MarketId) => BigNum
	) => Promise<VaultStats>;
}
