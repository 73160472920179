import {
	ExternalUiVaultConfig,
	SerializableNativeUiVaultConfig,
	UiVaultConfig,
} from 'src/@types/vaults';
import { PublicKey } from '@drift-labs/sdk';
import {
	ELEMENTAL_USDC_1_FUND,
	ELEMENTAL_SOL_1_FUND,
	ELEMENTAL_USDC_2_FUND,
	ELEMENTAL_SOL_2_FUND,
} from './elemental';
import { VAULT_CONFIGS_REMOTE_URL } from './misc';

const EXTERNAL_VAULTS: UiVaultConfig[] = [
	ELEMENTAL_USDC_1_FUND,
	ELEMENTAL_USDC_2_FUND,
	ELEMENTAL_SOL_1_FUND,
	ELEMENTAL_SOL_2_FUND,
];

interface VaultHolder {
	vaults: UiVaultConfig[]; // Replace `any` with your specific type if available, e.g. Vault[]
}

/**
 * Decorator to check if the vaults are initialized.
 */
function checkVaultsInitialized<T extends VaultHolder>(
	_target: any,
	_propertyKey: string,
	descriptor: TypedPropertyDescriptor<(this: T, ...args: any[]) => any>
) {
	const originalMethod = descriptor.value;

	descriptor.value = function (...args: any[]) {
		if (!this.vaults?.length) {
			throw new Error(
				'Vaults not initialized. Call setVaultsClientSide first.'
			);
		}
		return originalMethod.apply(this, args);
	};

	return descriptor;
}

export class UiVaults {
	static vaults: UiVaultConfig[] = [];

	/**
	 * Fetches the serialized native vaults configs from the remote URL.
	 * Mainly used to pass serialized data from server side UI to client side UI.
	 */
	static async fetchSerializedNativeVaults() {
		const res = await fetch(VAULT_CONFIGS_REMOTE_URL, {
			cache: 'no-store', // ensure that vaults configs are always fresh
		});
		const data = await res.json();

		return data as SerializableNativeUiVaultConfig[];
	}

	/**
	 * Fetches the native vaults configs from the remote URL.
	 */
	static async fetchNativeVaultsConfigs() {
		const serializedVaults = await this.fetchSerializedNativeVaults();

		return serializedVaults.map((vault) => new UiVaultConfig(vault));
	}

	/**
	 * Sets the vaults configs for the client side.
	 */
	static setVaultsClientSide(
		vaultsProgramVault: SerializableNativeUiVaultConfig[]
	) {
		this.vaults = vaultsProgramVault
			.map((vault) => new UiVaultConfig(vault))
			.concat(EXTERNAL_VAULTS);
	}

	@checkVaultsInitialized
	static getVaultConfig(
		vaultPubkeyString: string | PublicKey
	): UiVaultConfig | undefined {
		return this.vaults.find(
			(vault) => vault.vaultPubkeyString === vaultPubkeyString.toString()
		);
	}

	@checkVaultsInitialized
	static getNativeVaults(): UiVaultConfig[] {
		return this.vaults.filter((vault) => vault.isDriftVaultsProgramVault);
	}

	@checkVaultsInitialized
	static getExternalVaults(): ExternalUiVaultConfig[] {
		return this.vaults.filter(
			(vault) => !vault.isDriftVaultsProgramVault
		) as ExternalUiVaultConfig[];
	}

	@checkVaultsInitialized
	static getFeaturedVaults(): UiVaultConfig[] {
		return this.vaults.filter((vault) => vault.featured && !vault.hidden);
	}

	@checkVaultsInitialized
	static getAllVaults(): UiVaultConfig[] {
		return this.vaults;
	}
}
