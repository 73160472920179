'use client';

import {
	DriftVaults,
	Vault,
	VAULT_SHARES_PRECISION_EXP,
	VaultClient,
} from '@drift-labs/vaults-sdk';
import { MarketId, UIMarket } from '@drift/common';
import {
	BigNum,
	BN,
	DriftClient,
	FuelOverflowAccount,
	getFuelOverflowAccountPublicKey,
	getUserStatsAccountPublicKey,
	PublicKey,
	QUOTE_PRECISION_EXP,
	SpotMarketConfig,
	UserAccount,
	UserStatsAccount,
	ZERO,
} from '@drift-labs/sdk';
import { ApyReturnsLookup, VaultStats } from 'src/@types/vaults';
import { useEffect, useState } from 'react';
import { UiVaults } from 'src/constants/vaults';
import { DEFAULT_OFF_CHAIN_STATS } from 'src/constants/vaults/misc';
import { Program } from '@coral-xyz/anchor';
import { Connection } from '@solana/web3.js';
import { getVaultsApyReturns } from 'src/server-actions/getVaultsApyReturns';
import _ from 'lodash';
import { getFuelForVault } from 'src/utils/vault/math';

const fetchUserStats = async (
	driftClient: DriftClient,
	vaultPubkey: PublicKey
) => {
	const userStatsPubkey = getUserStatsAccountPublicKey(
		driftClient.program.programId,
		vaultPubkey
	);
	const userStats = await driftClient.program.account.userStats.fetch(
		userStatsPubkey
	);

	return userStats as UserStatsAccount;
};

const fetchDriftUserAccount = async (
	driftClient: DriftClient,
	vaultDriftUserPubkey: PublicKey
) => {
	const driftUserAccount = await driftClient.program.account.user.fetch(
		vaultDriftUserPubkey
	);

	return driftUserAccount as UserAccount;
};

const fetchFuelOverflowAccount = async (
	driftClient: DriftClient,
	vaultPubkey: PublicKey
) => {
	try {
		const vaultFuelOverflowAccount =
			await driftClient.program.account.fuelOverflow.fetch(
				getFuelOverflowAccountPublicKey(
					driftClient.program.programId,
					vaultPubkey
				)
			);

		return vaultFuelOverflowAccount as FuelOverflowAccount;
	} catch (e) {
		console.error(
			`couldn't fetch fuel overflow for vault ${vaultPubkey.toString()}`,
			e
		);
		return undefined;
	}
};

export type VaultsOnChainDataLookup = Record<
	string,
	{
		vaultAccountData: Vault;
		userStatsData: UserStatsAccount;
		vaultQuoteTvl: BN;
		vaultDriftUser: UserAccount;
		vaultFuelOverflowAccountData: FuelOverflowAccount;
	}
>;

const vaultDataDecoder = (
	buffer: Buffer,
	vaultProgram: Program<DriftVaults>
): Vault => {
	const account = vaultProgram.account.vault.coder.accounts.decode(
		'vault',
		buffer
	);
	return account;
};

const userStatsDataDecoder = (
	buffer: Buffer,
	driftProgram: DriftClient['program']
): UserStatsAccount => {
	const account = driftProgram.account.userStats.coder.accounts.decodeUnchecked(
		'UserStats',
		buffer
	);
	return account;
};

const fuelOverflowAccountDataDecoder = (
	buffer: Buffer,
	driftProgram: DriftClient['program']
): FuelOverflowAccount => {
	const account =
		driftProgram.account.fuelOverflow.coder.accounts.decodeUnchecked(
			'FuelOverflow',
			buffer
		);
	return account;
};

const userAccountDataDecoder = (
	buffer: Buffer,
	driftProgram: DriftClient['program']
): UserAccount => {
	const account = driftProgram.account.user.coder.accounts.decodeUnchecked(
		'User',
		buffer
	);
	return account;
};

// this method of processing is used to reduce the number of individual RPC calls needed to
// be made to get a vault's on-chain data, by combining most accounts into one `getMultipleAccountsInfo` RPC call
export const getMultipleOnChainVaultData = async (
	driftClient: DriftClient,
	vaultClient: VaultClient,
	connection: Connection,
	vaultPubKeys: PublicKey[]
): Promise<VaultsOnChainDataLookup> => {
	// create pairs of vault public keys and its user stats account
	const pubKeysToFetch = vaultPubKeys
		.map((vaultPubKey) => [
			vaultPubKey,
			getUserStatsAccountPublicKey(driftClient.program.programId, vaultPubKey),
			new PublicKey(
				UiVaults.getVaultConfig(vaultPubKey.toString())?.driftUserPubkeyString
			),
		])
		.flat();

	const chunks = _.chunk(pubKeysToFetch, 100); // getMultipleAccountsInfo has a limit of 100 accounts per call
	const responses = await Promise.all(
		chunks.map((chunk) => connection.getMultipleAccountsInfo(chunk))
	);

	const accountsFetched = responses.flat();

	// has to be separate from the other accounts because it's optional and can break the response if not present
	const fuelOverflowAccountKeysToFetch = vaultPubKeys
		.map(
			(vaultPubKey) =>
				getFuelOverflowAccountPublicKey(
					driftClient.program.programId,
					vaultPubKey
				) ?? undefined
		)
		.flat();

	const fuelOverflowChunks = _.chunk(fuelOverflowAccountKeysToFetch, 100);

	const fuelOverflowResponses = await Promise.all(
		fuelOverflowChunks.map((chunk) => connection.getMultipleAccountsInfo(chunk))
	);

	const fuelOverflowAccountsFetched = fuelOverflowResponses.flat();

	const vaultsOnChainDataLookup: VaultsOnChainDataLookup = {};

	// process each pair of data
	for (let i = 0; i < vaultPubKeys.length; i++) {
		const responseIndex = i * 3;
		const vaultAccountBuffer = accountsFetched[responseIndex].data;
		const userStatsBuffer = accountsFetched[responseIndex + 1].data;
		const driftUserAccountBuffer = accountsFetched[responseIndex + 2].data;
		const fuelOverflowAccountBuffer =
			fuelOverflowAccountsFetched[i]?.data ?? undefined;

		const vaultAccountData = vaultDataDecoder(
			vaultAccountBuffer,
			// @ts-ignore
			vaultClient.program
		);
		const userStatsData = userStatsDataDecoder(
			userStatsBuffer,
			driftClient.program
		);
		const driftUserAccountData: UserAccount = userAccountDataDecoder(
			driftUserAccountBuffer,
			driftClient.program
		);

		const vaultFuelOverflowAccountData: FuelOverflowAccount =
			fuelOverflowAccountBuffer
				? fuelOverflowAccountDataDecoder(
						fuelOverflowAccountBuffer,
						driftClient.program
				  )
				: undefined;

		const vaultPubKeyString = vaultPubKeys[i].toString();
		vaultsOnChainDataLookup[vaultPubKeyString] = {
			vaultAccountData,
			userStatsData,
			vaultQuoteTvl: ZERO,
			vaultDriftUser: driftUserAccountData,
			vaultFuelOverflowAccountData,
		};
	}

	const vaultQuoteTvlPromises = vaultPubKeys.map((vaultPubKey) => {
		return vaultClient.calculateVaultEquity({
			vault: vaultsOnChainDataLookup[vaultPubKey.toString()].vaultAccountData,
		});
	});
	const vaultsQuoteTvl = await Promise.all(vaultQuoteTvlPromises);

	vaultsQuoteTvl.forEach((vaultQuoteTvl, index) => {
		vaultsOnChainDataLookup[vaultPubKeys[index].toString()].vaultQuoteTvl =
			vaultQuoteTvl;
	});

	return vaultsOnChainDataLookup;
};

export function constructVaultStats(
	vaultPubKey: string,
	vaultOnChainData: VaultsOnChainDataLookup[string],
	apyReturnStat: ApyReturnsLookup[string] | undefined,
	oraclePriceGetter: (marketId: MarketId) => BigNum
): VaultStats {
	const uiVaultConfig = UiVaults.getVaultConfig(vaultPubKey);
	const uiMarket = UIMarket.createSpotMarket(uiVaultConfig.depositAsset);
	const marketConfig = uiMarket.market as SpotMarketConfig;
	const oraclePriceBigNum = oraclePriceGetter(uiMarket.marketId);

	const offChainStats = apyReturnStat
		? { ...apyReturnStat, hasLoadedOffChainStats: true }
		: DEFAULT_OFF_CHAIN_STATS;

	if (oraclePriceBigNum.eqZero()) {
		return {
			vaultAccount: vaultOnChainData.vaultAccountData,
			vaultUserStatsAccount: vaultOnChainData.userStatsData,
			vaultFuelOverflowAccount: vaultOnChainData.vaultFuelOverflowAccountData,
			isOnChainStatsLoaded: false,
			totalBasePnl: BigNum.from(ZERO, marketConfig.precisionExp),
			totalQuotePnl: BigNum.from(ZERO, QUOTE_PRECISION_EXP),
			tvlBase: BigNum.from(ZERO, marketConfig.precisionExp),
			tvlQuote: BigNum.from(ZERO, QUOTE_PRECISION_EXP),
			capacityPct: 0,
			volume30Days: BigNum.from(ZERO, QUOTE_PRECISION_EXP),
			isUncappedCapacity: false,
			totalShares: BigNum.from(ZERO, VAULT_SHARES_PRECISION_EXP),
			vaultRedeemPeriodSecs: ZERO,
			notionalGrowthQuotePnl: BigNum.from(ZERO, QUOTE_PRECISION_EXP),
			hasLoadedOnChainStats: false,
			profitShare: 0,
			fuelEarned: 0,
			...offChainStats,
		};
	}

	const vaultAccountData = vaultOnChainData.vaultAccountData;
	const userStats = vaultOnChainData.userStatsData;

	const vaultQuoteTvl = BigNum.from(
		vaultOnChainData.vaultQuoteTvl,
		QUOTE_PRECISION_EXP
	);
	const vaultBaseTvl = vaultQuoteTvl
		.shift(marketConfig.precisionExp)
		.div(oraclePriceBigNum);
	const totalBasePnl = vaultBaseTvl.sub(
		BigNum.from(vaultAccountData.netDeposits, marketConfig.precisionExp)
	);
	const totalQuotePnl = totalBasePnl
		.mul(oraclePriceBigNum)
		.shiftTo(QUOTE_PRECISION_EXP);
	const capacityPct = vaultAccountData.maxTokens.eqn(0) // uncapped capacity
		? '0'
		: vaultBaseTvl.toPercentage(
				BigNum.from(vaultAccountData.maxTokens, marketConfig.precisionExp),
				marketConfig.precisionExp.toNumber()
		  );

	const volume30Days = BigNum.from(
		userStats.makerVolume30D.add(userStats.takerVolume30D),
		QUOTE_PRECISION_EXP
	);

	const vaultDriftUserQuoteNetDeposits = BigNum.from(
		vaultOnChainData.vaultDriftUser.totalDeposits.sub(
			vaultOnChainData.vaultDriftUser.totalWithdraws
		),
		QUOTE_PRECISION_EXP
	);
	const notionalGrowthQuotePnl = vaultQuoteTvl.sub(
		vaultDriftUserQuoteNetDeposits
	);

	return {
		vaultAccount: vaultAccountData,
		vaultUserStatsAccount: vaultOnChainData.userStatsData,
		vaultFuelOverflowAccount: vaultOnChainData.vaultFuelOverflowAccountData,
		isOnChainStatsLoaded: true,
		totalBasePnl,
		totalQuotePnl,
		tvlBase: vaultBaseTvl,
		tvlQuote: vaultQuoteTvl,
		capacityPct: Math.min(+capacityPct, 100),
		isUncappedCapacity: vaultAccountData.maxTokens.eqn(0),
		volume30Days,
		totalShares: BigNum.from(
			vaultAccountData.totalShares,
			VAULT_SHARES_PRECISION_EXP
		),
		vaultRedeemPeriodSecs: vaultAccountData.redeemPeriod,
		notionalGrowthQuotePnl,
		hasLoadedOnChainStats: true,
		profitShare: vaultAccountData.profitShare,
		fuelEarned: getFuelForVault(
			vaultOnChainData.userStatsData,
			vaultOnChainData.vaultFuelOverflowAccountData
		),
		...offChainStats,
	};
}

/**
 * Fetches the on-chain vault stats.
 */
export const getSingleVaultStats = async (
	driftClient: DriftClient,
	vaultClient: VaultClient,
	vaultPubkey: PublicKey,
	apyReturnStat: ApyReturnsLookup[string],
	oraclePriceGetter: (marketId: MarketId) => BigNum
): Promise<VaultStats> => {
	const uiVaultConfig = UiVaults.getVaultConfig(vaultPubkey.toString());
	const [
		vaultAccountData,
		vaultQuoteTvlBN,
		userStats,
		vaultDriftUserAccount,
		vaultFuelOverflowAccountData,
	] = await Promise.all([
		vaultClient.getVault(vaultPubkey),
		vaultClient.calculateVaultEquity({
			address: vaultPubkey,
		}),
		fetchUserStats(driftClient, vaultPubkey),
		fetchDriftUserAccount(
			driftClient,
			new PublicKey(uiVaultConfig.driftUserPubkeyString)
		),
		fetchFuelOverflowAccount(driftClient, vaultPubkey),
	]);

	const vaultStats = constructVaultStats(
		vaultPubkey.toString(),
		{
			vaultAccountData,
			userStatsData: userStats,
			vaultFuelOverflowAccountData: vaultFuelOverflowAccountData,
			vaultQuoteTvl: vaultQuoteTvlBN,
			vaultDriftUser: vaultDriftUserAccount,
		},
		apyReturnStat,
		oraclePriceGetter
	);

	return vaultStats;
};

const DEFAULT_VAULTS_STATS: ApyReturnsLookup = {};

/**
 * Fetches the apy and returns of all vaults.
 */
export const useVaultsApyReturnsLookup = () => {
	const [data, setData] = useState<ApyReturnsLookup>(DEFAULT_VAULTS_STATS);

	useEffect(() => {
		getVaultsApyReturns().then((res) => {
			setData(res);
		});
	}, []);

	return data;
};
