'use client';

import TextField from '../Inputs/TextField';
import { ErrorFilled, Search } from '@drift-labs/icons';
import RoundedGradientBorderBox from '../RoundedGradientBorderBox';
import { useEffect, useRef, useState } from 'react';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import { twMerge } from 'tailwind-merge';

export const SearchBar = ({
	searchValue,
	setSearchValue,
	placeholder,
	textFieldClassName,
	bgClassName,
	autoFocus = true,
}: {
	searchValue: string;
	setSearchValue: (value: string) => void;
	placeholder: string;
	textFieldClassName?: string;
	bgClassName?: string;
	autoFocus?: boolean;
}) => {
	const isMobile = useIsMobileScreenSize();
	const [isFocused, setIsFocused] = useState(false);
	const inputRef = useRef(null);

	useEffect(() => {
		if (inputRef.current && !isMobile && autoFocus) {
			inputRef.current.focus();
		}
	}, [autoFocus]);

	return (
		<RoundedGradientBorderBox
			className={`w-full`}
			borderColour={isFocused ? 'var(--app-gradient)' : 'var(--input-border)'}
			borderWidth="1px"
		>
			<div
				className={twMerge(
					'flex items-center w-full p-2 bg-input-bg rounded-sm',
					bgClassName
				)}
			>
				<Search className="w-4 h-4" color="var(--icon-default)" />
				<TextField.Search
					ref={inputRef}
					type="text"
					value={searchValue}
					onChange={(value) => {
						setSearchValue(value);
					}}
					placeholder={placeholder}
					className={twMerge(
						'w-full h-full !text-[13px] min-w-[100px] placeholder:text-text-tertiary',
						bgClassName,
						textFieldClassName
					)}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
				/>
				<ErrorFilled
					size={16}
					className={twMerge(
						'[&>svg>path]:fill-[var(--icon-default)] cursor-pointer invisible',
						!!searchValue && 'visible'
					)}
					onClick={() => setSearchValue('')}
				/>
			</div>
		</RoundedGradientBorderBox>
	);
};
