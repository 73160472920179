import {
	ExternalUiVaultConfig,
	IExternalVaultApiAdapter,
	VaultManagerConfig,
	VaultStats,
} from 'src/@types/vaults';
import { BigNum, QUOTE_PRECISION_EXP, ZERO } from '@drift-labs/sdk';
import { GET_DEFAULT_VAULT_STATS, VAULT_MANAGER_IMG_BUCKET } from '../misc';
import { VAULT_SHARES_PRECISION_EXP } from '@drift-labs/vaults-sdk';
import { SPOT_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';
import { MarketId } from '@drift/common';

const BACKEND_API_ENDPOINT = 'https://acrobackend-8afg.onrender.com/api/vault';

const ELEMENTAL_VAULT_MANAGER: VaultManagerConfig = {
	name: 'Elemental',
	imgSrc: `${VAULT_MANAGER_IMG_BUCKET}/elemental_square.webp`,
	twitterHandle: 'elementaldefi',
};

type ElementalApiVault = {
	fund_name: string;
	apy: number;
	apr: number;
	tvl: number;
	capacity: number;
	total_earnings: number;
	cumulative_return: number;
	max_daily_drawdown: number;
	lastUpdated: string;
};

class ElementalApiAdapter implements IExternalVaultApiAdapter {
	/**
	 * fetchPromise caches the in-flight request to prevent duplicate API calls
	 * while the first request is still pending
	 */
	private fetchPromise: Promise<ElementalApiVault[]> | null = null;

	constructor(
		private readonly fundName: string,
		private readonly marketIndex: number,
		private readonly isUncappedCapacity: boolean
	) {}

	async getVaultStats(
		getOraclePriceForMarket: (marketId: MarketId) => BigNum
	): Promise<VaultStats> {
		const spotMarketConfig = SPOT_MARKETS_LOOKUP[this.marketIndex];

		if (!spotMarketConfig) {
			return GET_DEFAULT_VAULT_STATS(ZERO);
		}

		try {
			if (!this.fetchPromise) {
				this.fetchPromise = new Promise((res) => {
					fetch(BACKEND_API_ENDPOINT)
						.then((response) => {
							response
								.json()
								.then((data) => {
									res(data);
								})
								.catch((err) => {
									console.error('ElementalApiAdapter ~ fetch ~ err:', err);
									res([]);
								});
						})
						.catch((err) => {
							console.error('ElementalApiAdapter ~ fetch ~ err:', err);
							res([]);
						});
				});
			}

			const data = await this.fetchPromise;

			const fundData = data.find((fund) => fund.fund_name === this.fundName);

			if (!fundData) {
				return GET_DEFAULT_VAULT_STATS(spotMarketConfig.precisionExp);
			}

			const tvlQuote = BigNum.fromPrint(
				fundData.tvl.toString(),
				QUOTE_PRECISION_EXP
			);
			const oraclePrice = getOraclePriceForMarket(
				MarketId.createSpotMarket(this.marketIndex)
			);
			const tvlBase = oraclePrice.eqZero()
				? BigNum.zero(spotMarketConfig.precisionExp)
				: tvlQuote.shift(spotMarketConfig.precisionExp).div(oraclePrice);

			const totalBasePnl = BigNum.fromPrint(
				fundData.total_earnings.toString(),
				spotMarketConfig.precisionExp
			);
			const totalQuotePnl = totalBasePnl
				.mul(oraclePrice)
				.shiftTo(QUOTE_PRECISION_EXP);

			return {
				capacityPct: fundData.capacity,
				isUncappedCapacity: this.isUncappedCapacity,
				tvlQuote,
				tvlBase,
				totalBasePnl,
				totalQuotePnl,
				volume30Days: BigNum.zero(QUOTE_PRECISION_EXP),
				isOnChainStatsLoaded: true,
				totalShares: BigNum.zero(VAULT_SHARES_PRECISION_EXP),
				vaultRedeemPeriodSecs: ZERO,
				apys: {
					'7d': fundData.apy,
					'30d': fundData.apy,
					'90d': fundData.apy,
				},
				maxDrawdownPct: fundData.max_daily_drawdown,
				numOfVaultSnapshots: 0,
				notionalGrowthQuotePnl: BigNum.zero(QUOTE_PRECISION_EXP),
				hasLoadedOffChainStats: true,
				hasLoadedOnChainStats: true,
				profitShare: 0,
				vaultAccount: undefined,
				vaultUserStatsAccount: undefined,
				vaultFuelOverflowAccount: undefined,
				fuelEarned: 0,
				externalExtraStats: {
					rois: {
						// TODO :: Need elemental to provide this data
						'7d': null,
						'30d': null,
						'90d': null,
					},
				},
			};
		} catch (err) {
			console.error('ElementalApiAdapter.getVaultStats ~ err:', err);
			return GET_DEFAULT_VAULT_STATS(spotMarketConfig.precisionExp);
		}
	}
}

export const ELEMENTAL_USDC_1_FUND = new ExternalUiVaultConfig({
	name: 'USDC 1 Fund',
	vaultManager: ELEMENTAL_VAULT_MANAGER,
	vaultPubkeyString: 'elemental_usdc_1_fund',
	vaultManagerPubkeyString: '',
	driftUserPubkeyString: '',
	description:
		"A delta-neutral fund that generates consistent fixed yields through funding rates on Drift's perpetuals, using an innovative Compound Fund structure to deliver stable returns while minimizing re-balancing needs and maintaining resilience during market stress.",
	depositAsset: 0,
	assetsOperatedOn: [],
	externalDataApiAdapter: new ElementalApiAdapter('USDC Fund', 0, false),
	externalUiLink: 'https://elemental.fund/',
	featured: false,
	startTs: 1721606400, // 22 Jul 2024
});

export const ELEMENTAL_USDC_2_FUND = new ExternalUiVaultConfig({
	name: 'USDC 2 Fund',
	vaultManager: ELEMENTAL_VAULT_MANAGER,
	vaultPubkeyString: 'elemental_usdc_2_fund',
	vaultManagerPubkeyString: '',
	driftUserPubkeyString: '',
	description:
		"A delta-neutral fund that generates consistent fixed yields through funding rates on Drift's perpetuals, using an innovative Compound Fund structure to deliver stable returns while minimizing re-balancing needs and maintaining resilience during market stress.",
	depositAsset: 0,
	assetsOperatedOn: [],
	externalDataApiAdapter: new ElementalApiAdapter('USDC2 Fund', 0, false),
	externalUiLink: 'https://elemental.fund/',
	featured: false,
	startTs: 1735862400, // 3 Jan 2025
});

export const ELEMENTAL_SOL_1_FUND = new ExternalUiVaultConfig({
	name: 'SOL 1 Fund',
	vaultManager: ELEMENTAL_VAULT_MANAGER,
	vaultPubkeyString: 'elemental_sol_1_fund',
	vaultManagerPubkeyString: '',
	driftUserPubkeyString: '',
	description:
		"A delta-neutral fund that generates consistent fixed yields through funding rates on Drift's perpetuals, using an innovative Compound Fund structure to deliver stable returns while minimizing re-balancing needs and maintaining resilience during market stress.",
	depositAsset: 1,
	assetsOperatedOn: [],
	externalDataApiAdapter: new ElementalApiAdapter('SOL Fund', 1, false),
	externalUiLink: 'https://elemental.fund/?fund=sol',
	startTs: 1729814400, // 25 Oct 2024
});

export const ELEMENTAL_SOL_2_FUND = new ExternalUiVaultConfig({
	name: 'SOL 2 Fund',
	vaultManager: ELEMENTAL_VAULT_MANAGER,
	vaultPubkeyString: 'elemental_sol_2_fund',
	vaultManagerPubkeyString: '',
	driftUserPubkeyString: '',
	description:
		"A delta-neutral fund that generates consistent fixed yields through funding rates on Drift's perpetuals, using an innovative Compound Fund structure to deliver stable returns while minimizing re-balancing needs and maintaining resilience during market stress.",
	depositAsset: 1,
	assetsOperatedOn: [],
	externalDataApiAdapter: new ElementalApiAdapter('SOL2 Fund', 1, false),
	externalUiLink: 'https://elemental.fund/?fund=sol',
	startTs: 1736985600, // 16 Jan 2025
});
