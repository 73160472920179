import {
	calculateVaultDepositorFuel,
	Vault,
	VAULT_SHARES_PRECISION_EXP,
	VaultDepositor,
} from '@drift-labs/vaults-sdk';
import {
	BigNum,
	BN,
	FuelOverflowAccount,
	PERCENTAGE_PRECISION,
	QUOTE_PRECISION_EXP,
	UserStatsAccount,
	ZERO,
} from '@drift-labs/sdk';
import {
	SerializedVaultDepositorRecord,
	SerializedVaultSnapshot,
} from 'src/db/schema';
import { MarketId } from '../../../../drift-common/common-ts/lib/types/MarketId';
import invariant from 'tiny-invariant';
import {
	ExternalUiVaultConfig,
	UiVaultConfig,
	VaultStats,
} from 'src/@types/vaults';
import dayjs from 'dayjs';
import { VaultStatWithConfig } from 'src/stores/vaultsStore/useVaultsStore';
import { SPOT_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';

/**
 * Returns the balance of a vault depositor, after fees if `afterFees` is true.
 */
export const getVaultDepositorBalance = (
	vaultDepositorAccountData:
		| (Pick<VaultDepositor, 'vaultShares'> &
				Partial<
					Pick<
						VaultDepositor,
						| 'cumulativeProfitShareAmount'
						| 'netDeposits'
						| 'lastWithdrawRequest'
					>
				>)
		| undefined,
	vaultAccountData: Pick<Vault, 'totalShares' | 'profitShare'> | undefined,
	vaultTvlBase: BigNum | undefined,
	depositAssetPrecisionExp: BN,
	afterFees = false
) => {
	if (
		!vaultDepositorAccountData ||
		!vaultAccountData ||
		!vaultTvlBase ||
		vaultAccountData.totalShares?.eqn(0)
	) {
		return BigNum.from(0, depositAssetPrecisionExp);
	}

	const isInWithdrawalState =
		vaultDepositorAccountData.lastWithdrawRequest?.shares.gtn(0);

	// active vault shares refers to shares that are not being withdrawn, hence still profiting from the vault
	const userActiveVaultSharesBN = isInWithdrawalState
		? vaultDepositorAccountData.vaultShares.sub(
				vaultDepositorAccountData.lastWithdrawRequest?.shares ?? ZERO
		  )
		: vaultDepositorAccountData.vaultShares;
	const userActiveVaultShares = BigNum.from(
		userActiveVaultSharesBN,
		VAULT_SHARES_PRECISION_EXP
	);
	const userNonActiveVaultShares = isInWithdrawalState
		? BigNum.from(
				vaultDepositorAccountData.lastWithdrawRequest.shares,
				VAULT_SHARES_PRECISION_EXP
		  )
		: BigNum.zero(VAULT_SHARES_PRECISION_EXP);

	const totalVaultShares = BigNum.from(
		vaultAccountData.totalShares,
		VAULT_SHARES_PRECISION_EXP
	);

	const userActiveBalanceBaseValue = userActiveVaultShares
		.mul(vaultTvlBase)
		.div(totalVaultShares)
		.shiftTo(depositAssetPrecisionExp);
	const userNonActiveBalanceBaseValue = userNonActiveVaultShares
		.mul(vaultTvlBase)
		.div(totalVaultShares)
		.shiftTo(depositAssetPrecisionExp);
	// withdrawal amount is capped at the value of the last withdraw request
	const userMaxNonActiveBalanceBaseValue = isInWithdrawalState
		? BigNum.min(
				userNonActiveBalanceBaseValue,
				BigNum.from(
					vaultDepositorAccountData.lastWithdrawRequest.value,
					depositAssetPrecisionExp
				)
		  )
		: userNonActiveBalanceBaseValue;

	if (afterFees) {
		invariant(
			vaultDepositorAccountData.cumulativeProfitShareAmount,
			'Need to provide cumulativeProfitShareAmount to calculate balance after fees'
		);
		invariant(
			vaultDepositorAccountData.netDeposits,
			'Need to provide netDeposits to calculate balance after fees'
		);

		const highWaterMark =
			vaultDepositorAccountData.cumulativeProfitShareAmount.add(
				vaultDepositorAccountData.netDeposits
			);

		const taxableGains = userActiveBalanceBaseValue.sub(
			BigNum.from(highWaterMark, depositAssetPrecisionExp)
		);

		if (!taxableGains.gtZero()) {
			return userActiveBalanceBaseValue.add(userMaxNonActiveBalanceBaseValue);
		}

		const feesPayable = taxableGains.scale(
			new BN(vaultAccountData.profitShare),
			PERCENTAGE_PRECISION
		);
		const userActiveBalanceAfterFees =
			userActiveBalanceBaseValue.sub(feesPayable);

		return userActiveBalanceAfterFees.add(userMaxNonActiveBalanceBaseValue);
	}

	return userActiveBalanceBaseValue.add(userMaxNonActiveBalanceBaseValue);
};

export const getMaxDailyDrawdownFromHistory = (
	vaultSnapshots: Pick<
		SerializedVaultSnapshot,
		'ts' | 'totalAccountBaseValue' | 'netDeposits' | 'totalAccountQuoteValue'
	>[],
	valueField: 'totalAccountBaseValue' | 'totalAccountQuoteValue'
) => {
	const formattedSnapshots = vaultSnapshots.map((snapshot) => {
		const basePnl = new BN(snapshot.totalAccountBaseValue).sub(
			new BN(snapshot.netDeposits)
		);

		return {
			ts: +snapshot.ts,
			basePnl,
			tvlBase: new BN(snapshot[valueField]),
		};
	});

	const sortedSnapshots = formattedSnapshots.sort((a, b) => a.ts - b.ts);
	let maxDrawdown = 0;

	for (let i = 0; i < sortedSnapshots.length - 1; i++) {
		const currentDayAllTimeDayPnl = sortedSnapshots[i].basePnl;
		const previousDayAllTimeDayPnl = sortedSnapshots[i - 1]?.basePnl ?? ZERO;

		if (currentDayAllTimeDayPnl > previousDayAllTimeDayPnl) continue; // made profit for that day; no drawdown

		const currentDayPnl = currentDayAllTimeDayPnl.sub(previousDayAllTimeDayPnl);
		const currentDayTotalAccValue = sortedSnapshots[i].tvlBase;

		if (currentDayTotalAccValue.eqn(0)) {
			continue;
		}

		const drawdown =
			currentDayPnl
				.mul(PERCENTAGE_PRECISION)
				.div(currentDayTotalAccValue.sub(currentDayPnl))
				.toNumber() / PERCENTAGE_PRECISION.toNumber();

		if (drawdown < maxDrawdown) {
			maxDrawdown = drawdown;
		}
	}

	return maxDrawdown;
};

// if undefined, use the actual apy value
const APY_CLAMP = {
	min: 0,
	max: 100,
};

export const getApyToUse = (
	uiVaultConfig: UiVaultConfig,
	calculatedApy: number,
	numOfVaultSnapshots: number
) => {
	const isUsingTemporaryApy =
		uiVaultConfig.temporaryApy && numOfVaultSnapshots < 30;

	const apy = isUsingTemporaryApy ? uiVaultConfig.temporaryApy : calculatedApy;
	const isExceedingMaxApy = apy > APY_CLAMP.max;

	const apyString =
		isExceedingMaxApy && isUsingTemporaryApy
			? `> ${APY_CLAMP.max.toFixed(2)}%` // don't allow vault managers to over-optimistically set target apy
			: `${apy.toFixed(2)}%`;

	return {
		isUsingTemporaryApy,
		apy,
		apyString,
	};
};

export const getVaultApyDays = (numOfVaultSnapshots: number) => {
	const numOfDays = Math.min(numOfVaultSnapshots, 90);

	if (numOfDays < 2) {
		return `${numOfDays} day`;
	} else {
		return `${numOfDays} days`;
	}
};

export const getVaultDepositorNotionalNetDeposits = (
	vaultDepositorHistory: SerializedVaultDepositorRecord[]
) => {
	const notionalNetDeposits = vaultDepositorHistory.reduce((acc, record) => {
		const notionalBigNum = BigNum.from(
			record.notionalValue,
			QUOTE_PRECISION_EXP
		);

		if (record.action === 'deposit') {
			return acc.add(notionalBigNum);
		} else if (record.action === 'withdraw') {
			return acc.sub(notionalBigNum);
		} else {
			return acc;
		}
	}, BigNum.from(0, QUOTE_PRECISION_EXP));

	return notionalNetDeposits;
};

export const getVaultAge = (vaultStat: VaultStatWithConfig): number => {
	if (vaultStat.numOfVaultSnapshots) {
		return vaultStat.numOfVaultSnapshots;
	}
	return !vaultStat.uiVaultConfig.isDriftVaultsProgramVault
		? dayjs().diff(
				dayjs.unix((vaultStat.uiVaultConfig as ExternalUiVaultConfig).startTs),
				'days'
		  )
		: 0;
};

export const getAuthorityVaultBalance = (
	vaultDepositorAccounts: VaultDepositor[],
	vaultStat: VaultStats & { uiVaultConfig: UiVaultConfig },
	type: 'base' | 'quote',
	getOraclePrice?: (marketId: MarketId) => BigNum
) => {
	const basePrecisionExp =
		SPOT_MARKETS_LOOKUP[vaultStat.uiVaultConfig.depositAsset].precisionExp;

	const vaultDepositor = vaultDepositorAccounts
		.filter((acc) => !!acc)
		.find(
			(vaultDepositor) =>
				vaultDepositor.vault.toString() ===
				vaultStat.uiVaultConfig.vaultPubkeyString
		);

	const vaultDepositorBalance = vaultDepositor
		? getVaultDepositorBalance(
				vaultDepositor,
				{
					totalShares: vaultStat.totalShares.val,
					profitShare: vaultStat.profitShare,
				},
				vaultStat.tvlBase,
				basePrecisionExp,
				true
		  )
		: BigNum.zero(basePrecisionExp);

	if (type === 'quote' && getOraclePrice) {
		const marketId = MarketId.createSpotMarket(
			vaultStat.uiVaultConfig.depositAsset
		);

		const oraclePrice = getOraclePrice(marketId);

		return vaultDepositorBalance.mul(oraclePrice).shiftTo(QUOTE_PRECISION_EXP);
	}

	return vaultDepositorBalance;
};

export const getAuthorityVaultFuelBalance = (
	vaultDepositorAccounts: VaultDepositor[],
	vaultStat: VaultStats & { uiVaultConfig: UiVaultConfig }
): BigNum => {
	if (!vaultStat.isOnChainStatsLoaded) {
		return BigNum.zero();
	}

	const vaultDepositor = vaultDepositorAccounts
		.filter((acc) => !!acc)
		.find(
			(vaultDepositor) =>
				vaultDepositor.vault.toString() ===
				vaultStat.uiVaultConfig.vaultPubkeyString
		);

	if (!vaultDepositor) {
		return BigNum.zero();
	}

	const vaultDepositorFuel = calculateVaultDepositorFuel(
		vaultDepositor,
		vaultStat.vaultAccount,
		vaultStat.vaultUserStatsAccount,
		vaultStat.vaultFuelOverflowAccount
	);

	return BigNum.from(vaultDepositorFuel);
};

export const getFuelForVault = (
	vaultStatsAccount: UserStatsAccount,
	vaultFuelOverflowAccount?: FuelOverflowAccount
): number => {
	let fuelTotalForAuthority =
		vaultStatsAccount.fuelBorrows +
		vaultStatsAccount.fuelDeposits +
		vaultStatsAccount.fuelInsurance +
		vaultStatsAccount.fuelMaker +
		vaultStatsAccount.fuelTaker +
		vaultStatsAccount.fuelPositions;

	// vault has fuel overflow, add the additional fuel to the total
	if (vaultFuelOverflowAccount) {
		const totalOverflowFuel =
			(vaultFuelOverflowAccount.fuelBorrows as BN).toNumber() +
			(vaultFuelOverflowAccount.fuelDeposits as BN).toNumber() +
			(vaultFuelOverflowAccount.fuelInsurance as BN).toNumber() +
			(vaultFuelOverflowAccount.fuelMaker as BN).toNumber() +
			(vaultFuelOverflowAccount.fuelTaker as BN).toNumber() +
			(vaultFuelOverflowAccount.fuelPositions as BN).toNumber();

		fuelTotalForAuthority += totalOverflowFuel;
	}

	return fuelTotalForAuthority;
};
