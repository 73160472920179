import { AccountData } from 'src/stores/useDriftAccountsStore';
import {
	JLP_MARKET_INDEX,
	SPOT_MARKETS_LOOKUP,
} from 'src/environmentVariables/EnvironmentVariables';
import {
	BN,
	PERCENTAGE_PRECISION,
	SIX,
	ZERO,
	BigNum,
	PRICE_PRECISION,
} from '@drift-labs/sdk';
import { MAIN_POOL_ID, USDC_SPOT_MARKET_INDEX } from '@drift/common';

const JLP_PRECISION_EXP =
	SPOT_MARKETS_LOOKUP[JLP_MARKET_INDEX]?.precisionExp ?? SIX;
const USDC_PRECISION_EXP =
	SPOT_MARKETS_LOOKUP[USDC_SPOT_MARKET_INDEX]?.precisionExp ?? SIX;
const ZERO_JLP_BIG_NUM = BigNum.from(ZERO, JLP_PRECISION_EXP);
const ZERO_USDC_BORROW_BIG_NUM = BigNum.from(ZERO, USDC_PRECISION_EXP);

/*
 * Liabilities = Perp Positions, Spot Borrows, Negative Unsettled P&L
 *
 * (Brackets) = Optional
 *
 * Scenarios
 * 1. JLP + (Other Collaterals) -> Migrate everything
 * 2. JLP + USDC borrows -> Migrate everything
 * 3. JLP + (Other Collaterals) + USDC borrows + (Other Liabilities) -> Migrate some JLP and USDC borrow that results in an equal or lower leverage
 *
 * If liability is greater than initial margin, migration will not be allowed
 *
 */

export class JlpMigrationCalculator {
	private marginInfo: AccountData['marginInfo'];
	private client: AccountData['client'];
	private spotBalances: AccountData['spotBalances'];

	constructor({
		marginInfo,
		client,
		spotBalances,
	}: Pick<AccountData, 'marginInfo' | 'client' | 'spotBalances'>) {
		this.marginInfo = marginInfo;
		this.client = client;
		this.spotBalances = spotBalances;
	}

	getJlpSpotBalance = () => {
		return this.spotBalances.find(
			(balance) => balance.asset.marketIndex === JLP_MARKET_INDEX
		);
	};

	/**
	 * JLP Migration is only allowed if
	 * - is Main Pool subaccount
	 * - JLP spot balance exists
	 * - User's liability does not exceed initial margin. transfer_pool ix looks at initial margin to determine if migration is allowed
	 */
	public checkIsMigrationAllowed = (
		jlpPoolJlpSpotMarketInitialWeight: number
	) => {
		if (!this.client) return false;

		if (this.client.getUserAccount().poolId !== MAIN_POOL_ID) return false;

		const userHealthComponents = this.client.getHealthComponents({
			marginCategory: 'Initial',
		});
		const jlpDeposit = userHealthComponents.deposits.find(
			(deposit) => deposit.marketIndex === JLP_MARKET_INDEX
		);
		if (!jlpDeposit) return false;

		// if isolated pool's JLP's initial asset weight (IAW) is lower than user's main pool JLP IAW, don't recommend to migrate
		// we intentionally ignore IMF to be in play for now
		const mainPoolJlpMarketInitialWeight = jlpDeposit.weight.toNumber() / 100;
		if (mainPoolJlpMarketInitialWeight > jlpPoolJlpSpotMarketInitialWeight)
			return false;

		const hasAllowableJlpToMigrate = this.getMaxJlpMigrationAmount().gtZero();

		return hasAllowableJlpToMigrate;
	};

	/**
	 * Returns the maximum amount of JLP that can be migrated
	 */
	public getMaxJlpMigrationAmount = (): BigNum => {
		// get jlp spot balance initial margin. transfer_pool ix looks at initial margin to determine if migration is allowed
		const userHealthComponents = this.client.getHealthComponents({
			marginCategory: 'Initial',
		});

		// JLP + (Other Collaterals) - USDC borrows - (Other Liabilities)
		// (Brackets) = Optional
		// By taking JLP and USDC borrows out of the equation, Free Collateral = (Other Collaterals) - (Other Liabilities)

		// Case 1: JLP needed to support remaining liabilities without USDC borrow :: [Other Liabilities > Other Collaterals]
		// ------: Max JLP is the remaining JLP; remaining JLP can definitely support the USDC borrow, otherwise account will be below initial margin requirement
		// ------: Hence, max USDC is the existing USDC borrow

		// Case 2: JLP not needed to support remaining liabilities without USDC borrow :: [Other Liabilities <= Other Collaterals]
		// Case 2a: JLP can fully support the USDC borrow
		// ------: Max JLP is the existing JLP balance; Max USDC borrow is the existing USDC borrow
		// Case 2b: JLP cannot fully support the USDC borrow
		// ------: Max JLP is the existing JLP balance; Max USDC borrow is the margin equivalent to the max JLP

		const jlpDeposit = userHealthComponents.deposits.find(
			(deposit) => deposit.marketIndex === JLP_MARKET_INDEX
		);
		if (!jlpDeposit) return ZERO_JLP_BIG_NUM;
		const jlpDepositInitialMargin = jlpDeposit.weightedValue;

		const usdcBorrow = userHealthComponents.borrows.find(
			(borrow) => borrow.marketIndex === USDC_SPOT_MARKET_INDEX
		);
		const usdcBorrowInitialMarginReq = usdcBorrow?.weightedValue ?? ZERO;

		const nonJlpAssetsInitialMargin = this.marginInfo.totalCollateral.sub(
			jlpDepositInitialMargin
		);
		const nonUsdcBorrowLiabilitiesInitialMarginReq =
			this.marginInfo.initialReq.sub(usdcBorrowInitialMarginReq);

		const jlpMarginRequiredToSupportRemainingLiabilities = BN.max(
			nonUsdcBorrowLiabilitiesInitialMarginReq.sub(nonJlpAssetsInitialMargin),
			ZERO
		);
		const jlpMarginRequiredToSupportRemainingLiabilitiesWithBuffer = // buffer allows for fluctuation in prices that can cause resulting margin requirement to exceed initial margin
			jlpMarginRequiredToSupportRemainingLiabilities
				.mul(new BN(1005))
				.div(new BN(1000));

		if (jlpMarginRequiredToSupportRemainingLiabilitiesWithBuffer.eq(ZERO)) {
			// even though the logic below this condition accounts for the case where JLP is not needed at all to support remaining liabilities,
			// we still want to return early here to avoid calculations for dust positions that could result in precision loss
			return BigNum.from(jlpDeposit.size, JLP_PRECISION_EXP);
		}

		const maxJlpInitialMarginMigrationAmount = jlpDepositInitialMargin.sub(
			jlpMarginRequiredToSupportRemainingLiabilitiesWithBuffer
		);
		const maxUnweightedJlpMigrationAmount = maxJlpInitialMarginMigrationAmount
			.mul(PERCENTAGE_PRECISION)
			.div(jlpDeposit.weight)
			.div(new BN(100)); // because weight is in percentage

		const maxJlpMigrationAmountBase = maxUnweightedJlpMigrationAmount
			.mul(jlpDeposit.size)
			.div(jlpDeposit.value);

		return BigNum.from(maxJlpMigrationAmountBase, JLP_PRECISION_EXP);
	};

	/**
	 * Returns the maximized amount of USDC borrows to be migrated along with the JLP amount input
	 */
	public getUsdcBorrowMigrationAmountFromJlp = (
		jlpAmount: BN,
		jlpPoolJlpSpotInitialWeight: BN
	): BigNum => {
		const userHealthComponents = this.client.getHealthComponents({
			marginCategory: 'Initial',
		});

		const jlpDeposit = userHealthComponents.deposits.find(
			(deposit) => deposit.marketIndex === JLP_MARKET_INDEX
		);
		if (!jlpDeposit) return ZERO_USDC_BORROW_BIG_NUM;

		const usdcBorrow = userHealthComponents.borrows.find(
			(borrow) => borrow.marketIndex === USDC_SPOT_MARKET_INDEX
		);

		if (!usdcBorrow) return ZERO_USDC_BORROW_BIG_NUM;

		const jlpOraclePrice = jlpDeposit.value
			.mul(PRICE_PRECISION)
			.div(jlpDeposit.size);
		const jlpAmountInitialMargin = jlpAmount
			.mul(jlpOraclePrice)
			.mul(jlpPoolJlpSpotInitialWeight)
			.div(PRICE_PRECISION)
			.div(PERCENTAGE_PRECISION);

		const usdcBorrowMarginSupportedByJlpInput = jlpAmountInitialMargin; // we maximize the margin supported by the JLP amount
		const usdcBorrowAmount = BN.min(
			usdcBorrowMarginSupportedByJlpInput
				.mul(PERCENTAGE_PRECISION)
				.div(new BN(100)) // because weight is in percentage
				.div(usdcBorrow.weight),
			usdcBorrow.value
		);

		return BigNum.from(usdcBorrowAmount, USDC_PRECISION_EXP);
	};
}
