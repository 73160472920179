import { BigNum, MarginCategory, QUOTE_PRECISION_EXP } from '@drift-labs/sdk';
import Text from './Text/Text';
import UI_UTILS from 'src/utils/uiUtils';
import {
	ACCOUNT_HEALTH_PERCENT_CRITICAL,
	ACCOUNT_HEALTH_PERCENT_WARNING,
} from 'src/constants/constants';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import { Info } from '@drift-labs/icons';
import Tooltip from './Tooltip/Tooltip';
import useShowAccountValues from 'src/hooks/useShowAccountValues';

const dot = (
	<div className="absolute left-0 translate-x-[-50%] top-[-1px] h-[12px] w-[12px] rounded-full bg-white border-[2px] border-darkBlue-110"></div>
);

const MarginRequirementBar = ({
	marginCategory = 'Maintenance',
	showDot = false,
	/**
	 * Set this to true only when rendereing on a page. Set to false inside the health modal.
	 */
	showLabelTooltips = true,
	dotNotionalValue = 0,
	dotTooltipContent,
	userKey,
	forceColorClassName,
}: {
	marginCategory: MarginCategory;
	showLabelTooltips?: boolean;
	showDot?: boolean;
	dotNotionalValue?: number;
	dotTooltipContent?: React.ReactNode;
	userKey: string;
	forceColorClassName?: string;
}) => {
	const showAccountValues = useShowAccountValues();
	const connected = useWalletIsConnected();
	const userAccountData = useDriftAccountStore((s) => s.accounts[userKey]);
	const marginInfo = userAccountData?.marginInfo;
	const user = userAccountData?.client;

	const initialMarginReq = BigNum.from(
		marginInfo?.initialReq ?? 0,
		QUOTE_PRECISION_EXP
	).toNum();
	const maintenanceMarginReq = BigNum.from(
		marginInfo?.maintenanceReq ?? 0,
		QUOTE_PRECISION_EXP
	).toNum();

	const totalMaintenanceCollateral = BigNum.from(
		user?.getTotalCollateral('Maintenance', true) ?? 0,
		QUOTE_PRECISION_EXP
	).toNum();

	const totalInitialCollateral = BigNum.from(
		user?.getTotalCollateral('Initial', true) ?? 0,
		QUOTE_PRECISION_EXP
	).toNum();

	const initialMarginPctOfTotalCollateral =
		connected && totalInitialCollateral
			? (initialMarginReq / totalInitialCollateral) * 100
			: 0;

	const maintenanceMarginPctOfTotalCollateral =
		connected && totalMaintenanceCollateral
			? (maintenanceMarginReq / totalMaintenanceCollateral) * 100
			: 0;

	const initialMarginPctDisplay = `${initialMarginPctOfTotalCollateral.toFixed(
		1
	)}%`;

	const maintenanceMarginPctDisplay = `${maintenanceMarginPctOfTotalCollateral.toFixed(
		1
	)}%`;

	const marginPctOfTotalCollateral =
		marginCategory === 'Maintenance'
			? maintenanceMarginPctOfTotalCollateral
			: initialMarginPctOfTotalCollateral;

	const hasMarginReq =
		marginCategory === 'Maintenance'
			? maintenanceMarginReq > 0
			: initialMarginReq > 0;

	const marginUsedClassName = hasMarginReq
		? forceColorClassName
			? forceColorClassName
			: 100 - marginPctOfTotalCollateral >= ACCOUNT_HEALTH_PERCENT_WARNING
			? 'bg-positive-green'
			: 100 - marginPctOfTotalCollateral >= ACCOUNT_HEALTH_PERCENT_CRITICAL
			? 'bg-warn-yellow'
			: 'bg-negative-red'
		: 'bg-button-secondary-bg';

	const marginPctDisplay =
		marginCategory === 'Maintenance'
			? maintenanceMarginPctDisplay
			: initialMarginPctDisplay;

	const marginReqNum =
		marginCategory === 'Maintenance' ? maintenanceMarginReq : initialMarginReq;

	const totalCollateralNotional =
		marginCategory === 'Maintenance'
			? totalMaintenanceCollateral
			: totalInitialCollateral;

	const totalCollateralNotionalDisplay = UI_UTILS.toNotional(
		totalCollateralNotional
	);

	const dotLeft = Math.max(
		1,
		dotNotionalValue ? (dotNotionalValue / totalCollateralNotional) * 100 : 0
	);

	return (
		<div className="w-full">
			<div className="flex flex-row items-end justify-between w-full">
				<div>
					<Text.BODY3 className="text-text-secondary">
						{'Liabilities '}
						{showLabelTooltips && (
							<Tooltip
								className="inline"
								content={
									<Text.BODY3>
										The total{' '}
										{marginCategory === 'Maintenance'
											? 'maintenance'
											: 'initial'}{' '}
										weighted value of your liabilities.{' '}
										{marginCategory === 'Maintenance'
											? 'If this value exceeds your total maintenance collateral, your account can be liquidated'
											: 'If this value exceeds your total initial collateral, your account cannot take risk-increasing actions.'}
										<br />
										<br />
										Liabilities can include borrows, open positions, and open
										orders
									</Text.BODY3>
								}
								placement="top"
								allowHover
							>
								<Info size={14} className="relative top-[3px]" />
							</Tooltip>
						)}
					</Text.BODY3>
					<div className="h-1" />
					<Text.BODY1
						className={
							connected && user ? 'text-text-default' : 'text-text-tertiary'
						}
					>
						{showAccountValues ? UI_UTILS.toNotional(marginReqNum) : '*****'} (
						{marginPctDisplay})
					</Text.BODY1>
				</div>
				<div className="text-right">
					<Text.BODY3 className="text-text-secondary">
						Total Collateral{' '}
						{showLabelTooltips && (
							<Tooltip
								className="inline"
								content={
									<>
										<Text.BODY3>
											The total{' '}
											{marginCategory === 'Maintenance'
												? 'maintenance'
												: 'initial'}{' '}
											weighted value of your collateral.{' '}
											{marginCategory === 'Maintenance'
												? 'If the maintenance weighted value of your liabilities exceeds this value, your account can be liquidated'
												: 'If the initial weighted value of your liabilities exceeds this value, your account cannot take risk-increasing actions'}
										</Text.BODY3>
									</>
								}
								placement="top"
								allowHover
							>
								<Info size={14} className="relative top-[3px]" />
							</Tooltip>
						)}
					</Text.BODY3>
					<div className="h-1" />
					<Text.BODY1
						className={
							connected && user ? 'text-text-default' : 'text-text-tertiary'
						}
					>
						{showAccountValues ? totalCollateralNotionalDisplay : '*****'}
					</Text.BODY1>
				</div>
			</div>
			{connected && user ? (
				<div
					className={`relative rounded-full p-[1px] h-[12px] my-3 bg-button-secondary-bg`}
				>
					<div
						className={`h-[12px] min-w-[12px] rounded-full bg-button-secondary-bg absolute left-[0px] top-[0px] ${marginUsedClassName}`}
						style={{
							width: `${Math.max(
								0,
								Math.min(marginPctOfTotalCollateral, 100)
							)}%`,
						}}
					></div>
					{showDot && connected && (
						<div
							className="absolute"
							style={{
								left: `${dotLeft}%`,
							}}
						>
							<Tooltip content={dotTooltipContent} placement="top">
								<div className="w-[20px] h-[20px] absolute left-[-12px]"></div>
								{dot}
							</Tooltip>
						</div>
					)}
				</div>
			) : (
				<div className="w-full relative rounded-full p-[1px] h-[12px] my-3 bg-button-secondary-bg" />
			)}
		</div>
	);
};

export default MarginRequirementBar;
