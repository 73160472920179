import { useQuery } from '@tanstack/react-query';
import { ONE_DAY_MS } from 'src/constants/constants';
import useDriftClient from '../useDriftClient';
import { EpochInfo } from '@solana/web3.js';

export function useCurrentEpochInfo() {
	const driftClient = useDriftClient();

	return useQuery<EpochInfo | undefined>({
		queryKey: ['currentEpochInfo'],
		staleTime: ONE_DAY_MS,
		queryFn: async () => {
			const result = await driftClient.connection.getEpochInfo();

			return result;
		},
		enabled: !!driftClient,
	});
}
